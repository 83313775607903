import "core-js/modules/es.array.push.js";
import { storeClassTeacher, getList } from "@/api/api";
import { USER_TYPE_TEACHER } from "@/utils/config";
export default {
  name: "index",
  props: ['Row'],
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {
        id: '',
        school_id: '',
        class_id: '',
        teacher_id: '',
        subject_id: ''
      },
      classList: [],
      teacherList: [],
      subjectList: []
    };
  },
  mounted() {},
  methods: {
    closeDiv() {
      this.$emit('closeDiv');
    },
    getInfo(row) {
      this.getSchoolList();
      if (row) {
        const data = JSON.parse(row);
        this.Info = {
          id: data.id || '',
          school_id: data.school_id || '',
          class_id: data.class_id || '',
          teacher_id: data.teacher_id || '',
          subject_id: data.subject_id || ''
        };
        this.getOptions();
      } else {
        this.Info = {
          id: '',
          school_id: '',
          class_id: '',
          teacher_id: '',
          subject_id: ''
        };
      }
    },
    getOptions() {
      getList({
        type: USER_TYPE_TEACHER,
        school_id: this.Info.school_id
      }, 'User').then(res => {
        this.teacherList = res.data;
      });
      getList({}, 'Subject').then(res => {
        this.subjectList = res.data;
      }), getList({
        chool_id: this.Info.school_id
      }, 'Clazz').then(res => {
        let option = [];
        for (let i in res.data) {
          option.push({
            name: res.data[i].grade.name + ' ' + res.data[i].name,
            id: res.data[i].id
          });
        }
        this.classList = option;
      });
    },
    handleSchoolChange() {
      if (this.Info.school_id > 0) {
        this.getOptions();
      } else {
        this.teacherList = [];
      }
    },
    getSchoolList() {
      let data = {};
      getList(data, 'School').then(res => {
        this.schoolList = res.data;
      });
    },
    subData() {
      //提交
      if (!this.Info.class_id) {
        this.$root.ElMessage.error('选择班级');
        return false;
      }
      if (!this.Info.teacher_id) {
        this.$root.ElMessage.error('选择教师');
        return false;
      }
      if (!this.Info.subject_id) {
        this.$root.ElMessage.error('选择科目');
        return false;
      }
      storeClassTeacher(this.Info).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          this.closeDiv();
          this.$emit('getList');
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    }
  }
};