import editDiv from "@/view/school/clazzTeacherEdit.vue";
import { getClassTeacherList, importClassTeacher, deleteClassTeacher } from "@/api/api";
export default {
  name: "clazzTeacherList",
  components: {
    editDiv
  },
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        name: '',
        subject_name: '',
        current: 1,
        total: 0
      },
      exportIds: ''
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.subject_name = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getClassTeacherList(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.data;
      });
    },
    showEdit(row) {
      this.dialogFormVisible = true;
      this.Info = row;
      let data = '';
      if (row) {
        data = JSON.stringify(this.Info);
      }
      this.$refs.editDiv.getInfo(data); //调用子组件方法
    },
    showRenew(id) {
      this.dialogFormVisible1 = true;
      this.$refs.renewDiv.getInfo(id); //调用子组件方法
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    /* 通用文件导入 */
    beforeAvatarUpload(e) {
      let formData = new FormData();
      formData.append('file', e);
      importClassTeacher(formData).then(res => {
        this.$root.ElMessageBox.alert(res.msg, {
          dangerouslyUseHTMLString: true
        }); //dangerouslyUseHTMLString: true 设置为html处理
        if (res.success) {
          this.search();
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    delete(id) {
      deleteClassTeacher(id).then(res => {
        this.$root.msgResut(res, this, 'getList');
      });
    }
  }
};